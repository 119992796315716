import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import EndingBanner from '../components/EndingBanner';
import multiTray from '../assets/imgs/multiTray.png';
import tenmindelivery from '../assets/imgs/tenmindelivery.png';

import Carousel1_1 from '../assets/imgs/carousal/1.png';
import Carousel1_2 from '../assets/imgs/carousal/2.png';
import Carousel1_3 from '../assets/imgs/carousal/3.png';

// categories
import Accessories from '../assets/imgs/categories/accessories.png';
import Cafe from '../assets/imgs/categories/cafe.png';
import Electronics from '../assets/imgs/categories/electronics.png';
import Food from '../assets/imgs/categories/food.png';

import MobileSS from '../assets/imgs/mobile-ss.png';

import OrderIcon from '../assets/imgs/icons/order-icon.svg';
import PayIcon from '../assets/imgs/icons/pay-icon.svg';
import ScanIcon from '../assets/imgs/icons/scan-icon.svg';

import Playstore from '../assets/imgs/store/playstore.png';
import Appstore from '../assets/imgs/store/appstore.png';

import CustomerSatIcon from '../assets/imgs/icons/customer-sat.svg';
import FastDeliveryIcon from '../assets/imgs/icons/fast-delivery.svg';
import ReliableIcon from '../assets/imgs/icons/reliable.svg';

import cycleguy from '../assets/imgs/cycleguy.png';
import sixtymin from '../assets/imgs/homedelivery.png';

import bottomVideo from '../assets/videos/bottomVideo.mp4';
import videoMain from '../assets/videos/main.mp4';

import secondSec2Img from '../assets/imgs/2ndSection.png';
import mainVideoPlaceholder from '../assets/imgs/main_video_placeholder.png';

import '../styles/home.css';
import { APP_STORE_LINK, PLAY_STORE_LINK } from '../constants/Links';
import BenefitsInHospitals from '../sections/BenefitsInHospitals';
import MedicalDeliveryService from '../sections/MedicalDeliveryService';

const Home = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  function handleContactUsLink() {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  }

  const [bottomVideoVisible, setBottomVideoVisible] = useState(false);

  return (
    <div className='home'>
      <div className='local-video-container'>
        {!bottomVideoVisible && (
          <img
            className='video-placeholder-img'
            src={mainVideoPlaceholder}
            alt=''
          />
        )}
        <video
          autoPlay
          muted
          loop
          id='myVideo'
          onLoadedData={() => setBottomVideoVisible(true)}
          className='local-video'
        >
          <source src={videoMain} type='video/mp4' width='100%' height='100%' />
        </video>
      </div>
      {/* <div className='video-section'>
        <div className='video-responsive'>
          <iframe
            width='100%'
            height='100%'
            src='https://www.youtube.com/embed/P82sBw9zrQo?&autoplay=1'
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
            className='video-frame'
          ></iframe>
        </div>
      </div> */}
      {/* <div className='home__banner'>
        <img src={gift_1_L} alt='' className='home__banner-img1' />
        <img src={gift_1_S} alt='' className='home__banner-img2' />
        <Banner />
      </div> */}
      <MedicalDeliveryService />
      <BenefitsInHospitals />
      <div className='home__deliverySection'>
        <img src={multiTray} alt='' />
        <img src={tenmindelivery} alt='' />
      </div>
      <div className='home__2ndSection'>
        <div className='home__2ndSectionMask'>
          <div className='home__2ndSection__landing-left'>
            <h2>
              Swift Robotics operates a robot delivery service inside shopping
              centres and office buildings.
            </h2>
            <div>
              <div className='home__2ndSection_item'>
                <div className='home__2ndSection_item_type'>
                  <img src={FastDeliveryIcon} />
                </div>
                <h3>Fast and Hassle free Indoor Delivery</h3>
              </div>
              <div className='home__2ndSection_item'>
                <div className='home__2ndSection_item_type'>
                  <img src={CustomerSatIcon} />
                </div>
                <h3>PR Stunt</h3>
              </div>
              <div className='home__2ndSection_item'>
                <div className='home__2ndSection_item_type'>
                  <img src={ReliableIcon} />
                </div>
                <h3>Free for Shopping Centres</h3>
              </div>
            </div>
          </div>
          <img className='home__landing-right-img' src={secondSec2Img} alt='' />
        </div>
        <img className='home__landing-right-img1' src={secondSec2Img} alt='' />
      </div>
      <div className='home__4thSection'>
        <img src={MobileSS} alt='' className='' />
        <div className='home__4thSection_right'>
          <h1>Swift Experience</h1>
          <div>
            <div className='home__4thSection_item'>
              <div className='home__4thSection_item_type'>
                <img src={OrderIcon} />
              </div>
              <div>
                <h2>Place an order</h2>
                <h4>Download the app & choose the products</h4>
              </div>
            </div>
            <div className='home__4thSection_item_divider' />
            <div className='home__4thSection_item'>
              <div className='home__4thSection_item_type'>
                <img src={ScanIcon} />
              </div>
              <div>
                <h2>Scan the nearest QR code</h2>
                <h4>for precise delivery location</h4>
              </div>
            </div>
            <div className='home__4thSection_item_divider' />
            <div className='home__4thSection_item'>
              <div className='home__4thSection_item_type'>
                <img src={PayIcon} />
              </div>
              <div>
                <h2>Pay & Relax</h2>
                <h4>Our Robots will deliver your order</h4>
              </div>
            </div>
          </div>
          <div className='store-buttons'>
            <img
              alt='appstore-download'
              onClick={() => window.open(APP_STORE_LINK, '_blank')}
              src={Appstore}
              className='appstore-img'
            />
            <img
              alt='playstore-download'
              onClick={() => window.open(PLAY_STORE_LINK, '_blank')}
              src={Playstore}
              className='playstore-img'
            />
          </div>
        </div>
      </div>
      {bottomVideoVisible ? (
        <div className='local-video-container'>
          <video autoPlay muted loop id='myVideo' className='local-video'>
            <source
              src={bottomVideo}
              type='video/mp4'
              width='100%'
              height='100%'
            />
          </video>
        </div>
      ) : (
        <></>
      )}
      <div className='home__3rdSection'>
        <div className='home__landing-left home__3rdSection-left'>
          <h1>Why choose us?</h1>
          <h5>
            <ul>
              <li>Avoid standing in long queue</li>
              <li>See menus of all participating Restaurants</li>
              <li>Get your order delivered by our Delivery Robots</li>
            </ul>
          </h5>
        </div>
        <div className='home__3rdSection-right'>
          <h2> Our popular categories</h2>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flex: 1 }}>
              <div className='home__3rdSection-category'>
                <img src={Cafe} />
              </div>
              <div className='home__3rdSection-category'>
                <img src={Food} />
              </div>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <div className='home__3rdSection-category'>
                <img src={Electronics} />
              </div>
              <div className='home__3rdSection-category'>
                <img src={Accessories} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='ending__banner_container'>
        <EndingBanner />
      </div>
    </div>
  );
};

export default Home;
