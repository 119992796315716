import React from "react";
import "../styles/banner.css";
import Avatar from "../assets/imgs/avatar.png";
import { MAIL_CONTACT_US_EMAIL } from "../constants/Links";
const EndingBanner = () => {
  return (
    <div className="ending__banner">
      <div style={{ width: "100%" }}>
        <p className="ending_banner_title">
          Get in touch
        </p>
        <a href={MAIL_CONTACT_US_EMAIL}>
          <div  className="endingBannerContactUsBtn">
            Contact Us
          </div>
        </a>
      </div>
      <img src={Avatar} className="ending__bannerAvatar" alt="banner" />
    </div>
  );
};

export default EndingBanner;
