import styles from "./index.module.css";

const BenefitsInHospitals = () => {
  return (
    <div className={styles.outerContainer}>
      <div className={styles.content}>
        <div className={styles.textContainer}>
          <h2>Benefits of Robots in Hospitals</h2>
          <ol>
            <li className={styles.textListItem}>Remote Consultation</li>
            <li className={styles.textListItem}>Remote Monitoring</li>
            <li className={styles.textListItem}>
              Support for healthcare staff
            </li>
            <li className={styles.textListItem}>Improved access to care</li>
            <li className={styles.textListItem}>Enhanced patient comfort</li>
          </ol>
        </div>
        <div className={styles.imageContainer}>
          <img
            className={styles.hospitalImg}
            alt="hospital"
            src={require("../../assets/imgs/hospital.png")}
          />
        </div>
      </div>
    </div>
  );
};

export default BenefitsInHospitals;
